
import {Component, Vue} from 'vue-property-decorator';
import {usedProducts, users} from '../../../../resources';
import {ObjectId} from 'bson';
import {UsedProduct} from '@/externals/MaxCI-UsedProduct-v1';

interface UsedGoods {
  _id: {userId: ObjectId; name?: string; phone?: string; avatar?: string};
  list: Array<UsedProduct>;
  show: boolean;
}
@Component({
  name: 'usedGoodsList',
})
export default class extends Vue {
  private listShow = true; //列表显示
  private operateId: ObjectId | null = null;
  private detailMsg: null | UsedGoods = null;
  private goodsIndex = 0;
  private listLoading = true;
  private applicationId = ObjectId.createFromHexString(
    this.$route.params.applicationId,
  );
  private goodsList: Array<UsedGoods> = [];
  private dialogDetail = false;

  async created() {
    this.updateList();
  }
  //查看详情
  private checkDetail(item: UsedGoods, index: number) {
    this.detailMsg = item;
    this.dialogDetail = true;
    this.goodsIndex = index;
  }
  private getPrice(price: number) {
    return (price / 100).toFixed(2).toString().split('.');
  }
  //图片显示连接
  get downloadUrl() {
    return this.$store.state.pConfig.downloadUrl;
  }
  private async updateList() {
    const usedProduct = await usedProducts.find(stage =>
      stage
        .$match(match =>
          match(
            f => f('spec')('applicationId'),
            e => e.$eq(this.applicationId),
          )(
            f => f('status')('phase'),
            e => e.$ne('售出'),
          ),
        )
        .$facet(facet =>
          facet('summary', summaryStage =>
            summaryStage
              .$sort(sort =>
                sort(f => f('metadata')('creationTimestamp'), '降序'),
              )
              .$group(
                e =>
                  e.$object(object =>
                    object('userId', e =>
                      e.$fieldPath(f => f('spec')('userId')),
                    ),
                  ),
                group =>
                  group('list', orders =>
                    orders.$push(push => push.$fieldPath(f => f('$ROOT'))),
                  ),
              )
              .$sort(sort =>
                sort(
                  f => f('list')(0)('metadata')('creationTimestamp'),
                  '降序',
                ),
              ),
          ),
        ),
    );
    const list = usedProduct[0].summary as Array<UsedGoods>;
    list.forEach(async item => {
      item._id.name = '';
      item._id.phone = '';
      item._id.avatar = '';
      item.show = true;
      const user = await users.find(stage =>
        stage.$match(match =>
          match(
            f => f('_id'),
            e => e.$eq(item._id.userId),
          )(
            f => f('spec')('applicationId'),
            e => e.$eq(this.applicationId),
          ),
        ),
      );
      if (user.length > 0) {
        item._id.name = user[0].spec.name;
        item._id.phone = user[0].spec.phone;
        item._id.avatar = user[0].spec.avatar;
      }
    });
    this.goodsList = list;
  }
  //商品上下架
  private changeStatus(index: number, goodsIndex: number) {
    usedProducts
      .update(
        filter =>
          filter(
            f => f('_id'),
            e => e.$eq(this.goodsList[index].list[goodsIndex]._id),
          )(
            f => f('spec')('applicationId'),
            e => e.$eq(this.applicationId),
          ),
        update =>
          update.$set(s =>
            s(
              f => f('spec')('inService'),
              !this.goodsList[index].list[goodsIndex].spec.inService,
            ),
          ),
      )
      .then(res => {
        if (res.length > 0) {
          this.$message.success(status ? '下架成功' : '上架成功');
          this.updateList();
        }
      });
  }
  //展开收起
  private showBtn(index: number) {
    this.goodsList[index].show = !this.goodsList[index].show;
  }
}
