
import {Component, Vue} from 'vue-property-decorator';
import Submenu from '@/components/Submenu/index.vue';
import usedService from './components/usedService/index.vue';
import usedGoodsList from './components/usedGoodsList/index.vue';
import usedLabel from './components/usedLabel/index.vue';

@Component({
  name: 'setting',
  components: {
    Submenu,
    usedService,
    usedGoodsList,
    usedLabel,
  },
})
export default class extends Vue {
  private menu = '';
  private submenu = [
    {
      top: '',
      list: [
        {name: '二手商品', active: true},
        {name: '商品标签', active: false},
        {name: '二手服务', active: false},
      ],
    },
  ];
  created() {
    this.$store.state.applicationId = this.$route.params.applicationId;
    this.menu = this.$route.query.menu
      ? (this.$route.query.menu as string)
      : '二手商品';
  }
}
